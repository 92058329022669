import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Main } from "components"

const NotFound = () => {
	return (
		<>
			<Main>
				<div
					style={{
						height: "70vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div style={{ width: 300, margin: "0 auto", textAlign: "center" }}>
						<StaticImage src="../assets/images/not_found.png" width={250} />
						<p style={{ marginTop: 20, textAlign: "center", letterSpacing: 10 }}>PAGE NOT FOUND</p>
					</div>
				</div>
			</Main>
		</>
	)
}

export default NotFound
